import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'

const Contact = (props) => {
    let data = props.data.wpgraphql;


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu} lang={props.pageContext.language} />
            <PageBanner
                pageTitle={data.page.contactPageAcf.title}
                homePageText={props.pageContext.language ==="EN" ? "Home" : (props.pageContext.language ==="FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")}
                activePageText={data.page.contactPageAcf.title}
            />
            <ContactInfo
                data={data.page.contactPageAcf.contactInfo}
            />
            <ContactForm
                lang={props.pageContext.language}
                data={data?.template?.contactInformationTemplate}
            />
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    );
}

export default Contact

export const pageQuery = graphql`
    query GET_CONTACT_US($id: ID! , $contactTemplateUri: ID!, $footerTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    contactPageAcf {
                    title
                    contactInfo{
                        title
                        content
                    }
                }
            }

            template(id: $contactTemplateUri, idType: SLUG) {
                contactInformationTemplate {
                    form {
                        content
                        title
                        subTitle
                        content
                        fullName
                        email{
                            label
                            errorMessage
                        }
                        subject
                        message{
                            label
                            errorMessage
                        }
                        telephone{
                            label
                            errorMessage
                        }
                        sendButton
                    }
                    responseMessages{
                        success
                        failed
                    }
                    image {
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp {
                                fluid(maxWidth: 585, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`