import React from 'react'
import Map from "../../assets/images/icons/bx-map.svg"
import Phone from "../../assets/images/icons/bxs-phone-call.svg"
import Clock from "../../assets/images/icons/bx-time-five.svg"




const ContactInfo = (props) => {
    const data = props.data

    const getIcon = (index) => {
        if (index === 0) {
            return (
                <>
                    <div className="icon">
                        <img src={Map} alt="map icon" style={{width: "75px",height: "85px",padding: "1rem"}} />
                    </div>
                </>
            )
        } else if (index === 1) {
            return (
                <>
                    <div className="icon">
                        <img src={Phone} alt="map icon" style={{width: "75px",height: "85px",padding: "1rem"}} />
                    </div>
                </>
            )
        }
        else if (index === 2) {
            return (
                <>
                    <div className="icon">
                        <img src={Clock} alt="map icon" style={{width: "75px",height: "85px",padding: "1rem"}} />
                    </div>
                </>
            )
        }
    }
    
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">

                    {data && data.map((ci, i) => (
                        <div className="col-lg-4 col-md-6" key={`cinfo-${i}`}>
                            <div className="contact-info-box">
                                {getIcon(i)}
                                <h3>{ci.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: ci.content }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ContactInfo